// Color
$primary-base:  #ac00e5 !default;

// RTL
$enable-ltr: true !default;
$enable-rtl: true !default;

// Font
$font-family-sans-serif: "Vazirmatn" !default;
$font-family-monospace: "Monaco" !default;

// Link
$link-color: #52a8ff !default;
$link-decoration: none !default;

// Modal
$modal-backdrop-opacity: 0 !default;

// Cards
$card-spacer-y: 1rem !default;
$card-spacer-x: 1rem !default;
$card-cap-padding-y: 1rem !default;
$card-cap-padding-x: 1rem !default;
