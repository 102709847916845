.center {
	position: absolute;
	top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.label {
  margin-left: 0.4rem;
  font-weight: bold;
  flex-shrink: 0;
  line-height: 38px;
}

.label-lg {
  @extend .label;
  width: 160px;
}

.date-control {
  @extend .form-control;
  font-size: unset !important;
  width: 100% !important;
}

.modal::before {
  content:"";
  background-color: #000015;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.select {
  &__menu {
    z-index: 2000 !important;
  }
  &__option {
    display: flex !important;
    align-items: center !important;
  }
  &__single-value {
    display: flex !important;
    align-items: center !important;
  }
  &__single-value--is-disabled {
    color: unset !important;
  }
  &__multi-value {
      &__label {
      padding-right: 8px !important;
    }
  }
  &__multi-value--is-disabled {
    color: unset !important;
  }
  &__control--is-disabled  {
    color: black !important;
    background-color: #d8dbe0 !important;
  }
}

.form-control-disabled {
  @extend .form-control;
  background-color: #d8dbe0;
  opacity: 1;
}

.multiline {
  white-space: pre-line;
}

.callout {
  margin-top: 0 !important;
  border-right-width: 3px !important;
}

.callout-info{
  border-right-color: #39f !important;
}

.modal-fullscreen {
  width: 100% !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.tiny {
  font-size: 0.7em;
}

.horizontal-separator {
  height: 1px;
  width: 100%;
  background-color: rgba(120, 120, 120,0.4);
}

.blured {
	-webkit-filter: blur(4px);
	-moz-filter: blur(4px);
	-o-filter: blur(4px);
	-ms-filter: blur(4px);
	filter: blur(4px);
	pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
  appearance: none;
}

.h-0 {
  height: 0;
}
.h-90 {
  height: 90%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width: 40%;
}
.w-45 {
  width: 45%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}

.btn-ghost-primary:disabled,
.btn-ghost-secondary:disabled,
.btn-ghost-success:disabled,
.btn-ghost-warning:disabled,
.btn-ghost-info:disabled,
.btn-ghost-danger:disabled,
.btn-ghost-light:disabled,
.btn-ghost-dark:disabled {
  border: 0 !important;
}

.chartjs-tooltip {
  z-index: 1055 !important;
}

.chartjs-tooltip-body-item > td > span {
  margin-right: 0px !important;
  margin-left: 10px !important;
}
